import TonWeb from 'tonweb';

const tonweb = new TonWeb();
const COMPRESSED_NFT_API_URL = "https://tonfox.tech/v1";

// Функция для преобразования массива байтов в Base64 URL-совместимую строку
const bytesToBase64Url = (bytes) => {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(bytes)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
};

export const processTransaction = async (itemIndex) => {
  try {
    // 1. Fetch collection state
    console.log(`Fetching collection state for item index ${itemIndex}`);
    const stateResponse = await fetch(`${COMPRESSED_NFT_API_URL}/state`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (!stateResponse.ok) {
      throw new Error('Failed to fetch collection state');
    }

    const state = await stateResponse.json();
    console.log('Collection state:', state);

    // 2. Fetch proof cell for a specific item (by its index)
    console.log(`Fetching proof cell for item index ${itemIndex}`);
    const itemResponse = await fetch(`${COMPRESSED_NFT_API_URL}/items/${itemIndex}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (!itemResponse.ok) {
      throw new Error('Failed to fetch proof cell');
    }

    const item = await itemResponse.json();
    console.log('Proof cell item:', item);

    // 3. Prepare payload for the claim transaction
    console.log(`Decoding proof cell for item index ${itemIndex}`);
    const proofCellDecoded = TonWeb.utils.base64ToBytes(item.proof_cell);
    console.log('Proof cell decoded:', proofCellDecoded);
    if (!proofCellDecoded) throw new Error('Failed to decode proof cell');

    console.log(`Creating proof cell from BOC for item index ${itemIndex}`);
    const proofCells = TonWeb.boc.Cell.fromBoc(proofCellDecoded);
    console.log('Proof cells:', proofCells);
    if (!proofCells || proofCells.length === 0) throw new Error('Failed to create proof cell from BOC');
    const proofCell = proofCells[0];

    // Используем текущее время как источник уникальности для nonce
    const uniqueNonce = Date.now() * Math.floor(Math.random() * 1000);
    console.log('Unique nonce:', uniqueNonce);

    console.log(`Building cell for payload for item index ${itemIndex}`);
    const cellBuilder = new TonWeb.boc.Cell();
    cellBuilder.bits.writeUint(0x13a3ca6, 32);
    cellBuilder.bits.writeUint(uniqueNonce, 64);
    cellBuilder.bits.writeUint(itemIndex, 256);
    cellBuilder.refs.push(proofCell);
    console.log('Cell builder:', cellBuilder);

    console.log(`Converting cell to BOC for item index ${itemIndex}`);
    const payload = await cellBuilder.toBoc(false);
    console.log('Payload:', payload);
    const bin = bytesToBase64Url(payload);
    console.log('Generated bin:', bin);

    if (!bin) {
      throw new Error('Failed to generate bin');
    }

    // 4. Complete the transaction
    const collectionAddress = new TonWeb.utils.Address(state.address);
    console.log(`Collection Address: ${collectionAddress.toString(true, true, false)}`);

    const amount = '85000000'; // Example amount, adjust as needed
    const link = `ton://transfer/${collectionAddress.toString(true, true, false)}?amount=${amount}&bin=${bin}`;

    console.log(`Generated link for item index ${itemIndex}: ${link}`);

    return { link, bin, collectionAddress: collectionAddress.toString(true, true, false), amount };
  } catch (error) {
    console.error(`Error processing transaction for item index ${itemIndex}: ${error.message}`);
    console.error(error.stack);
    throw new Error(`Failed to process transaction for item ${itemIndex}: ${error.message}`);
  }
};

