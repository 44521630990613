import React, { useState, useEffect } from 'react';
import { processTransaction } from '../utils/transactionUtils';
import { getAddressId } from '../utils/addressUtils';
import { useTonAddress } from '@tonconnect/ui-react';
import { TonConnectUIProvider, useTonConnectUI } from '@tonconnect/ui-react';

interface Button {
  label: string;
  uri: string;
}

interface CardData {
  name: string;
  description: string;
  image: string;
  content_url: string;
  buttons: Button[];
}

const Transaction: React.FC = () => {
  const [transactions, setTransactions] = useState<{ index: number, link: string, bin: string, collectionAddress: string, amount: string }[]>([]);
  const [errors, setErrors] = useState<{ index: number, error: string }[]>([]);
  const [cardData, setCardData] = useState<CardData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const userFriendlyAddress = useTonAddress();
  const [tonConnectUI] = useTonConnectUI();

  useEffect(() => {
    const fetchCardData = async (addressId: number) => {
      try {
        const response = await fetch(`https://raw.githubusercontent.com/ZodiackiIler/test-collection/main/data/${addressId}.json`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result: CardData = await response.json();
        setCardData(result);
      } catch (error) {
        setError('Failed to load card data');
      } finally {
        setLoading(false);
      }
    };

    const processTransactions = async () => {
      const addressId = getAddressId(userFriendlyAddress);
      if (addressId !== null) {
        try {
          const { link, bin, collectionAddress, amount } = await processTransaction(addressId);
          console.log(`link ${link}`);
          setTransactions(prev => {
            if (!prev.some(tx => tx.index === addressId)) {
              return [...prev, { index: addressId, link, bin, collectionAddress, amount }];
            }
            return prev;
          });
          fetchCardData(addressId);
        } catch (error) {
          setErrors(prev => {
            if (!prev.some(err => err.index === addressId)) {
              return [...prev, { index: addressId, error: (error as Error).message }];
            }
            return prev;
          });
        }
      }
    };

    processTransactions();
  }, [userFriendlyAddress]);

  const handleClaim = async () => {
    if (cardData && transactions.length > 0) {
      const { bin, collectionAddress, amount } = transactions[0];
      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
        messages: [
          {
            address: collectionAddress,
            amount: amount,
            payload: bin
          }
        ]
      };

      try {
        const result = await tonConnectUI.sendTransaction(transaction);
      } catch (e) {
        console.error(e);
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!cardData) {
    return null;
  }

  return (
      <div style={styles.card}>
        <img src={cardData.image} alt={cardData.name} style={styles.image} />
        <h2>{cardData.name}</h2>
        <p>{cardData.description}</p>
        <div style={styles.buttonsContainer}>
          <button onClick={handleClaim} style={styles.button}>
            Claim
          </button>
        </div>
      </div>
  );
};

const styles = {
  card: {
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '16px',
    maxWidth: '400px',
    margin: '0 auto',
  },
  image: {
    width: '100%',
    height: 'auto',
    borderRadius: '8px',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '16px',
  },
  button: {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: '#007bff',
    color: 'white',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
};

export default Transaction;
