import React from 'react';
import { TonConnectButton } from '@tonconnect/ui-react';
import styles from './Header.module.css';

const Header: React.FC = () => {
    return (
        <header className={styles.header}>
            <span>RubeTON Card Claim App</span>
            <TonConnectButton className={styles.button} />
        </header>
    );
};

export default Header;

