import holderData from './holder.json'; // Adjust the path if necessary

interface Holder {
    id: number;
    address: string;
}

export const getAddressId = (userAddress: string): number | null => {
    const holder: Holder | undefined = holderData.find(holder => holder.address === userAddress);
    return holder ? holder.id : null;
};
