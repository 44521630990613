import React from 'react';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import Header from './components/Header';
import Transaction from './components/Transaction';

const App: React.FC = () => {
    return (
        <TonConnectUIProvider manifestUrl="https://raw.githubusercontent.com/ton-connect/demo-dapp-with-react-ui/master/public/tonconnect-manifest.json">
            <Header />
            <main>
                <Transaction />
            </main>
        </TonConnectUIProvider>
    );
};

export default App;
